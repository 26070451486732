<template>
    <v-card class="" elevation="" max-width="" >
        <v-card-title> {{dados.titulo}} </v-card-title>
        <v-card-text>

            <div style="background-color:white;height:50vh;overflow:auto; scrollbar-width: none;">

            
                <div style="border-bottom:1px solid #999595;margin-bottom:10px;" v-for="item in dados.dados" :key="item.nome">
                    <div><h4>{{item.titulo}}</h4></div>
                    <div style="display:flex;">
                        <v-container class="" style="padding:0px!important;">
                            <v-row>
                                <v-col>
                                    <div>
                                        <div>{{item.dado1.nome}}</div>
                                        <div>{{item.dado1.valor}}</div>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div>
                                        <div>{{item.dado2.nome}}</div>
                                        <div>{{item.dado2.valor}}</div>
                                    </div>
                                </v-col>
                                <v-col>
                                    <div>
                                        <div style="font-weight:bold;">{{item.dado3.nome}}</div>
                                        <div>{{item.dado3.valor}}</div>
                                    </div>
                                </v-col>
                            </v-row>
                            </v-container>

                        
                        
                        
                    </div>
                </div>

                
                
                
            </div>
        </v-card-text>
    </v-card>
</template>

<script>

export default {
  components: {  },
  props:["dados"],
  data(){
    return{
      
      
    }
  },
  methods:{
    
  },
  async mounted(){

  }
}
</script>

<style lang="scss" scoped>


</style>
<template>
    <v-container style="padding-top:0px!important;">
       
            
                <!-- <div style="">
                     <v-container style="padding-top:10px!important;padding-bottom:0px!important;margin-bottom:0px!important;display:flex;">
                        <v-row>
                          <v-col cols="6" md="2" style="min-width:100px;"><a style="font-size:12px;text-decoration: underline;margin-right:20px;" @click="view = 0">Dashboard</a></v-col>
                          <v-col cols="6" md="2" style="min-width:100px;"><a style="font-size:12px;text-decoration: underline;margin-right:20px;" @click="direcionar('/Prioridades')">Atividades</a></v-col>
                          <v-col cols="6" md="2" style="min-width:100px;"><a style="font-size:12px;text-decoration: underline;margin-right:20px;" @click="direcionar('/Relatorios')">Relatorios</a></v-col>
                          <v-col cols="6" md="2" style="min-width:100px;"><a style="font-size:12px;text-decoration: underline;margin-right:20px;" :class="{'es-active': view == 1, 'es-inactive': view == 0}" @click="view=(view==0?1:0)">Maior e Menor Uso</a></v-col>
                          <v-col cols="6" md="2" style="min-width:100px;"></v-col>
                        </v-row>
                      </v-container>
                </div> -->
            
        
        

        <div v-if="view==0">
             <v-container class="">
                <v-row>

                    <!-- GRAFICO DE BARRA DE QUANTIDADES -->
                    <v-col cols="12" md="6">
                        <v-card class="" elevation="" max-width="" >
                            <v-card-title> Quantidade por Status </v-card-title>
                            <v-card-text>
                                <div style="background-color:white;height:50vh;overflow:auto;">
                                    <v-container class="" >
                                        <v-row v-for="clientePorStatus in listaClienteQtdePorStatus.listaDeClientes" :key="clientePorStatus.nome" style="">
                                            <v-col cols="12" md="6" :class="{'es-selected': clientePorStatus.idetapa == etapaSelecionada.idetapa, 'es-normal': clientePorStatus.idetapa != etapaSelecionada.idetapa }"
                                                @click="selecionarEtapa(clientePorStatus)"
                                            >{{clientePorStatus.nome}}</v-col>
                                            <v-col cols="12" md="5" :style="`display:flex;`">
                                                <div :style="`background-color:blue;height:30px;width:${(clientePorStatus.percentual+2).toFixed(2)}%;`">
                                                    <div style="color:white;font-weight:bold;padding-left:5px;padding-right:5px;padding-top:2px;">{{clientePorStatus.qtde}}</div>
                                                </div>
                                                <div style="margin-left:10px;">({{clientePorStatus.percentual.toFixed(2)}}%)</div>
                                            </v-col>                                        
                                        </v-row>
                                    </v-container>
                                        <div style="text-align:end;margin-top:20px;">
                                        <h4>
                                        Total:{{listaClienteQtdePorStatus.total}}
                                        </h4>
                                    </div>
                                </div>
                            </v-card-text>
                            <!-- <v-card-text>
                                <div style="text-align:end;">
                                    <h4>
                                    Total:{{listaClienteQtdePorStatus.totalClientes}}
                                    </h4>
                                </div>
                            </v-card-text> -->
                        </v-card>
                    </v-col>
                    <!-- FIM DO GRAFICO DE BARRA DE QUANTIDADES -->
                    <v-col cols="12" md="6">
                        <v-data-table 
                            :items="clientes"
                            :headers="cliHeaders"
                            :items-per-page="7"
                        >
                        </v-data-table>
                    </v-col>
                </v-row>
              </v-container>
        </div>

        <div v-if="view==1">
             <v-container class="">
                <v-row>
                    <v-col cols="12" md="6">
                        <v-text-field type="text" label="Nome Hotel Cliente"  v-model="clientePesquisaNome" outlined return-object hide-details dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field type="date" label="Data Inicial"  v-model="clientePesquisaDtInicial" outlined return-object hide-details dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field type="date" label="Data Final"  v-model="clientePesquisaDtFinal" outlined return-object hide-details dense>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-btn small elevation="" color="info" style="height:40px;" block @click="consultarclientesMaiorMenorUso()">Pesquisar</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6">
                      <ListaDeMaiorMenorUso :dados="dadosMaiorUso" />
                  </v-col>
                  <v-col cols="12" md="6">
                      <ListaDeMaiorMenorUso :dados="dadosMenorUso" />
                  </v-col>
                </v-row>
              </v-container>
        </div>

    </v-container>

    
</template>

<script>

import dash from '@/services/dashboard.service.js'
import ClienteServico from '@/services/cliente.service.js'
import sistemas from '@/services/sistema.service.js'
import ListaDeMaiorMenorUso from '@/components/Charts/ListaDeMaiorMenorUso.vue'
import dayjs from 'dayjs'

// import dayjs from 'dayjs'

export default {
  components: {
    ListaDeMaiorMenorUso
  },
  data(){
    return{
        clientePesquisaNome:null,
        clientePesquisaDtInicial:null,
        clientePesquisaDtFinal:null,
        dadosMaiorUso:{
            titulo:"",
            dados:[]
        },
        dadosMenorUso:{
            titulo:"",
            dados:[]
        },
        listaClienteQtdePorStatus:{
            totalClientes:0,
            listaDeClientes:[]
        },
        sistema:{id:1,nome:'Ficha Digital'},
        listadesistemas:[],
        modo:1,
        listademodos:[
            {id:1,nome:'Geral'},
            {id:2,nome:'Sem Utilização'},
        ],
        cliente:{},
        listadecliente:[],
        relatorio: {id:1, nome:'Utilização Diária'},
        listarelarios:[
                    {id:0, nome:'Implantações'},
                    {id:1, nome:'Utilização Diária'},
                    {id:6, nome:'Utilização Média'},
                    {id:4, nome:'Hoteis com Importação de Fichas nos ultimos 3 dias'},
                    {id:5, nome:'Hoteis com Finalização de Fichas nos ultimos 3 dias'},
                    {id:2, nome:'Hoteis sem Importação de Fichas a mais de 3 dia'},
                    {id:3, nome:'Hoteis sem Finalização de Fichas a mais de 3 dia'}
                ],
        headers:null,
        items:null,
        itemsmenos:null,
        itemsmais:null,
        diainicial:null,
        diafinal:null,
        sistemaSelecionado:null,
        view: 0,
        clientes: [],
        cliHeaders: [
            { text: 'Nome', value: 'nome' },
            { text: 'Contato', value: 'contato1'},
        ],
        etapaSelecionada: {}
    }
  },
  watch: {
    etapaSelecionada: {
        handler() {
            this.consultarClientesPorEtapa();
        },
        deep: true
    }
  },
  methods:{
        direcionar(pagina){
            this.$router.push(pagina)
        },
        async consultarClientesPorStatus(){
            
            this.listaClienteQtdePorStatus = []
            let dados = {
                    idsistema: this.sistemaSelecionado.id,
            }

            this.listaClienteQtdePorStatus = {total:0,listaDeClientes:[]}

            let listaDeRegistros =  await dash.consultarClientesPorStatus(dados).then(res=> res.data)
            //console.error(`${JSON.stringify(listaDeRegistros)}`);
            for(let i =0, len = listaDeRegistros.length; i<len; i++ ){
                let registro = listaDeRegistros[i]
                this.listaClienteQtdePorStatus.total = this.listaClienteQtdePorStatus.total + parseInt(registro.qtde)
            }
            
            for(let i =0, len = listaDeRegistros.length; i<len; i++ ){
                let registro = listaDeRegistros[i]
                this.listaClienteQtdePorStatus.listaDeClientes.push({idetapa:registro.idetapa,nome:registro.nomeetapa,qtde:registro.qtde,ordem:registro.ordem,percentual: (100*registro.qtde)/this.listaClienteQtdePorStatus.total },)
            }

            this.listaClienteQtdePorStatus.listaDeClientes = this.listaClienteQtdePorStatus.listaDeClientes.sort((a,b) => {
                return a.ordem > b.ordem? 1: -1
            })

            if (this.listaClienteQtdePorStatus.listaDeClientes.length > 0) {
                this.selecionarEtapa(this.listaClienteQtdePorStatus.listaDeClientes[0]);
            }

        },
        async consultarClientesPorEtapa(){
            
            let dados = {
                idsistema: this.sistemaSelecionado.id,
                idetapa: this.etapaSelecionada.idetapa,
            }
            //console.error(`consultarClientesPorEtapa(${JSON.stringify(dados)})`)
            this.clientes =  await dash.consultarClientesPorEtapa(dados).then(res=> res.data)
            
        },
        async consultaClientesMaiorUso(){
            
            this.dadosMaiorUso = null
            this.dadosMaiorUso = {titulo:"Maior Uso",dados:[]}

            let dados = {
                    idsistema: this.sistemaSelecionado.id,
                    diainicial:this.clientePesquisaDtInicial,
                    diafinal:this.clientePesquisaDtFinal,
                    nomecliente:this.clientePesquisaNome,
                    limite:10
            }
            // alert(JSON.stringify(dados,null,2))

            let listaDeRegistros =  await dash.consultaClientesMaiorUso(dados).then(res=> res.data)
            // alert(JSON.stringify(listaDeRegistros,null,2))
            for(let i =0, len = listaDeRegistros.length; i<len; i++ ){
                
                let registro = listaDeRegistros[i]
                 
                if(this.sistemaSelecionado.id == 1){

                    this.dadosMaiorUso.dados.push(
                        {
                            titulo:registro.cliente,
                            dado1:{nome:'Import.', valor:registro.fichasimportadas },
                            dado2:{nome:'Ckins', valor:registro.qtde_checkins_dia },
                            dado3:{nome:'Finalizadas.', valor:registro.fichasfinalizadas },
                        }
                    )

                }

                if(this.sistemaSelecionado.id == 2){

                    this.dadosMaiorUso.dados.push(
                        {
                            titulo:registro.cliente,
                            dado1:{nome:'Quarto', valor:registro.pedidosquarto },
                            dado2:{nome:'Mesa', valor:registro.pedidosmesa },
                            dado3:{nome:'Total.', valor:( parseInt(registro.pedidosquarto) + parseInt(registro.pedidosmesa) ) },
                        }
                    )
                    
                }

            }

            this.dadosMenorUso.dados.sort((a,b) =>{
                    return a.dado3.valor < b.dado3.valor? 1 : -1
            })

        },
        async consultaClientesMenorUso(){
            
            this.dadosMenorUso = null
            this.dadosMenorUso = {titulo:"Menor Uso",dados:[]}

            let dados = {
                    idsistema: this.sistemaSelecionado.id,
                    diainicial:this.clientePesquisaDtInicial,
                    diafinal:this.clientePesquisaDtFinal,
                    nomecliente:this.clientePesquisaNome,
                    limite:10
            }

            let listaDeRegistros =  await dash.consultaClientesMenorUso(dados).then(res=> res.data)
            // alert(JSON.stringify(listaDeRegistros,null,2))
            for(let i =0, len = listaDeRegistros.length; i<len; i++ ){
                
                let registro = listaDeRegistros[i]
                 
                if(this.sistemaSelecionado.id == 1){

                    this.dadosMenorUso.dados.push(
                        {
                            titulo:registro.cliente,
                            dado1:{nome:'Import.', valor:registro.fichasimportadas },
                            dado2:{nome:'Ckins', valor:registro.qtde_checkins_dia },
                            dado3:{nome:'Finalizadas.', valor:registro.fichasfinalizadas },
                        }
                    )

                }

                if(this.sistemaSelecionado.id == 2){

                    this.dadosMenorUso.dados.push(
                        {
                            titulo:registro.cliente,
                            dado1:{nome:'Quarto', valor:registro.pedidosquarto },
                            dado2:{nome:'Mesa', valor:registro.pedidosmesa },
                            dado3:{nome:'Total.', valor:( parseInt(registro.pedidosquarto) + parseInt(registro.pedidosmesa) ) },
                        }
                    )
                    
                }

                this.dadosMenorUso.dados.sort((a,b) =>{
                    return a.dado3.valor > b.dado3.valor? 1 : -1
                })
            }

        },
        async consultarclientesMaiorMenorUso(){

            await this.consultaClientesMaiorUso()
            await this.consultaClientesMenorUso()
            
        },
        async consultarDadosDashBoard(){

            await this.consultarClientesPorStatus()
            await this.consultaClientesMaiorUso()
            await this.consultaClientesMenorUso()
            // await this.requisitarClientes()
            // await this.selecionarTipoRelatorio()
            // //   this.diainicial = dayjs().format('YYYY-MM-DD')
            // //   this.diafinal = dayjs().format('YYYY-MM-DD')
            // await this.realizarConsulta()
            

        },
        async selecionarEtapa(etapa){
            this.etapaSelecionada = etapa;
        },
        async selecionarSistema(sistema){

            let copia =[...this.listadesistemas]
            this.listadesistemas = []
            
            let indice = await copia.findIndex((x) => { return x.id == sistema.id })
            this.sistemaSelecionado = copia[indice]

            for(let i = 0,len = copia.length; i < len; i++){
                copia[i].selecionado = false; 
            }
            copia[indice].selecionado = true
            this.listadesistemas = copia

            this.consultarDadosDashBoard()

        },
        limparFiltros(){
            this.sistema = {},
            this.cliente = {},
            this.diainicial = null 
            this.diafinal = null 
            this.relatorio = {}
        },
        selecionarTipoRelatorio(){
            // alert(JSON.stringify(this.sistema,null,2))
            if(this.sistema.id == 1){
                this.relatorio = {id:0, nome:'Implantações'}
                this.listarelarios =[
                    {id:0, nome:'Implantações'},
                    {id:1, nome:'Utilização Diária'},
                    {id:6, nome:'Utilização Média'},
                    {id:4, nome:'Hoteis com Importação de Fichas nos ultimos 3 dias'},
                    {id:5, nome:'Hoteis com Finalização de Fichas nos ultimos 3 dias'},
                    {id:2, nome:'Hoteis sem Importação de Fichas a mais de 3 dia'},
                    {id:3, nome:'Hoteis sem Finalização de Fichas a mais de 3 dia'}
                ]
            }

            if(this.sistema.id == 2){
                this.relatorio = {id:1, nome:'Cardapio'}
                this.listarelarios =[
                    {id:1, nome:'Cardapio'},
                ]
            }

        },
        async requisitarClientes(){
            this.listadecliente = await ClienteServico.buscar().then(res => res.data)
        },
        async realizarConsulta(){
            
    
            //FICHA DIGITAL
            if(this.sistema.id == 1){

                    this.headers = [
                            { text: 'Cliente',    align: 'start', value: 'cliente', sortable: false },
                            { text: 'Dia Utilização',    align: 'start', value: 'dia', sortable: true },
                            { text: 'Importadas',  align: 'start', value: 'fichasimportadas',  sortable: true},
                            { text: 'CkIns do Dia',  align: 'start', value: 'qtde_checkins_dia', sortable: true },
                            { text: 'Finalizadas',  align: 'start', value: 'fichasfinalizadas', sortable: true },
                            { text: '%',  align: 'start', value: 'porcentagemfinalizada', sortable: true },
                            { text: 'WebCheckins',  align: 'start', value: 'qtde_web_checkins', sortable: true },
                            { text: '%',  align: 'start', value: 'porcentagemwebcheckin', sortable: true },
                            { text: 'Ult. Importação',  align: 'start', value: 'dtultfichaimportada', sortable: true },
                            { text: 'Ult. Finalizada',  align: 'start', value: 'dtultfichafinalizada', sortable: true },
                    ]

                    if(this.relatorio.id == 0){

                        this.headers = [
                                // { text: 'Ordem Fluxo de Implantação',    align: 'start', value: 'ordem', sortable: false },
                                { text: 'Quantidade',    align: 'start', value: 'qtde', sortable: true },
                                { text: 'Etapa Atual',    align: 'start', value: 'nomeetapa', sortable: true }
                        ]

                        this.diainicial = null
                        this.diafinal = null
                    }

                    if(this.relatorio.id == 2){
                        this.diainicial = null
                        this.diafinal = null
                    }

                    if(this.relatorio.id == 3){
                        this.diainicial = null
                        this.diafinal = null
                    }
                
            }

            //FICHA CARDAPIO DIGITAL
            if(this.sistema == 2){

                this.headers = [
                        { text: 'Cliente',    align: 'start', value: 'id', sortable: false },
                        { text: 'Qtde Comandas Entregues',  align: 'start', value: 'nome',  sortable: false},
                        { text: 'Dt Ult. Comanda Entregue',  align: 'start', value: 'ativo', sortable: false },
                        { text: 'Dt Ult. Hospede Importado',  align: 'start', value: 'ativo', sortable: false },
                ]

            }

            //REALIZAR CONSULTA
            let dados = {
                    idsistema:this.sistema.id,
                    cliente:this.cliente.id,
                    diainicial:this.diainicial,
                    diafinal:this.diafinal,
                    tiporelatorio:this.relatorio.id
                    // idmodo:this.modo
                }
            this.itemsmais  = await dash.consultarDadosDashMais(dados).then(res=> res.data)


        },
        async listarSistemas(){
            this.listadesistemas = await sistemas.buscar({semimagem:true}).then(res => res.data)
            // this.listadesistemas = this.listadesistemas.sort((a,b) => { return a.nome >b.nome?1:-1 })
            this.listadesistemas = this.listadesistemas.sort((a,b) => { return a.qtdeclientes < b.qtdeclientes?1:-1 })
            this.listadesistemas[0].selecionado = true
            this.sistemaSelecionado = this.listadesistemas[0]
            // for(let i=0,len= this.listadesistemas.length; i < len; i++){
            //     alert(JSON.stringify(this.listadesistemas[i],null,2))
            // }
        },
  },

    
  async mounted(){
      this.clientePesquisaDtInicial = dayjs().subtract(7,'days').format('YYYY-MM-DD')
      this.clientePesquisaDtFinal = dayjs().format('YYYY-MM-DD')
      await this.listarSistemas();
      this.consultarDadosDashBoard();
  }
}
</script>

<style>
div::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */

}

.div {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
</style>

<style scoped>
.es-active {
    font-weight: bold;
}
.es-inactive {
    font-weight: normal;
}
.es-normal {
    cursor: pointer;
    border-radius: 5px;
}
.es-selected {
    cursor: pointer;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    background-color: #1377F2;
}
</style>